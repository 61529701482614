.ml-auto, .mx-auto {
      margin-left: auto!important;
}

.mr-auto, .mx-auto {
  margin-right: auto!important;
}
.mb-2, .my-2 {
      margin-bottom: .5rem!important;
}

@media (min-width: 576px) {
  .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
  }

  .transaction-card {
    min-height: 100px; 
  }
}

@media (min-width: 768px) {
  .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
  }

  .transaction-card {
    min-height: 153px; 
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
  }

  .transaction-card {
    min-height: 230px; 
  }
}



.card {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0,0,0,.125);
      border-radius: .25rem;
  margin-right: 15px;
      margin-left: 15px;
}

.card-body {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 1.25rem;
}

.justify-content-around {
      -ms-flex-pack: distribute!important;
      justify-content: space-around!important;
}

.flex-wrap {
      -ms-flex-wrap: wrap!important;
      flex-wrap: wrap!important;
}
.d-flex {
      display: -ms-flexbox!important;
      display: flex!important;
}

.p-1 {
      padding: .25rem!important;
}
.mh-100 {
      max-height: 100%!important;
}
.mw-100 {
      max-width: 100%!important;
}

.text-center {
      text-align: center!important;
}

.align-items-start {
      -ms-flex-align: start!important;
      align-items: flex-start!important;
}
.flex-column {
  -ms-flex-direction: column!important;
      flex-direction: column!important;
}

.card-header {
      padding: .75rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0,0,0,.03);
      border-bottom: 1px solid rgba(0,0,0,.125);
}

.mt-auto, .my-auto {
      margin-top: auto!important;
}

.no-before::before {
    width: 0px !important;
}